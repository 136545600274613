import { defaultOptions as countryOptions } from '@traveloka/soya-components/lib/components/common/Form/SelectCountryCode/defaultOptions';

import {
  TYPE_TRANSMISSION_AUTOMATIC,
  TYPE_TRANSMISSION_MANUAL,
  TYPE_DRIVER_WITH_DRIVER,
  TYPE_DRIVER_WITHOUT_DRIVER,
  TYPE_BOOKING_STATUS_BOOKED,
  TYPE_BOOKING_STATUS_ISSUED,
  TYPE_BOOKING_STATUS_CANCELLED,
  TYPE_SUPPLIER_SERVICE_PREMIUM,
  TYPE_SUPPLIER_SERVICE_STANDARD,
  TYPE_BOOKING_STATUS_EXPIRED,
  TYPE_VEHICLE_TYPE_MPV,
  TYPE_VEHICLE_TYPE_MINI_MPV,
  TYPE_VEHICLE_COMPACT_MPV,
  TYPE_VEHICLE_SUV,
  TYPE_VEHICLE_CITY_CAR,
  TYPE_VEHICLE_MINI_VAN,
  TYPE_VEHICLE_MINIBUS,
  TYPE_VEHICLE_SEDAN,
  TYPE_VEHICLE_LUXURY_SEDAN,
  TYPE_VEHICLE_LIMOUSINE,
  TYPE_DRIVER_QUEUE_STATUS_STANDBY,
  TYPE_DRIVER_QUEUE_STATUS_WAITING_FOR_BOOKING,
  TYPE_DRIVER_QUEUE_STATUS_ASSIGNED,
  TYPE_DRIVER_QUEUE_STATUS_REST,
  TYPE_DRIVER_QUEUE_STATUS_LEAVE_POOL,
  TYPE_DRIVER_QUEUE_STATUS_ABSENT_OUT,
  TYPE_DRIVER_QUEUE_STATUS_ON_THE_WAY,
  TYPE_VEHICLE_MOTORCYCLE,
  TYPE_INSURANCE_INSURANCE_BY_TRAVELOKA,
  TYPE_INSURANCE_INSURANCE_NOT_AVAILABLE,
  TYPE_INSURANCE_INSURANCE_BY_SUPPLIER,
  TYPE_INSURANCE_INSURANCE_BY_SUPPLIER_AND_TRAVELOKA,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_WAITING_FOR_ACKNOWLEDGE,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_ACKNOWLEDGED,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_DRIVER_ASSIGNED,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_DRIVER_CONFIRMED,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_TRIP_COMPLETED,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REFUNDED,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_REFUND,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_RESCHEDULED,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_RESCHEDULE,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_POTENTIAL_LATE,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_PAX_AGREE_TO_WAIT,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_ACKNOWLEDGE,
  TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_ASSIGN_DRIVER,
  TYPE_VEHICLE_MEDIUM_BUS,
  TYPE_VEHICLE_BIG_BUS,
  TYPE_VEHICLE_LUXURY_BUS,
  TYPE_VEHICLE_BUS,
  TYPE_VEHICLE_PICKUP_TRUCK,
  TYPE_CUTOFF_TYPE_TWENTY_FOUR_HOURS,
  TYPE_CUTOFF_TYPE_END_OF_DAY,
  TYPE_PAYMENT_ELIGIBLE_TYPE_DEFAULT,
  TYPE_PAYMENT_ELIGIBLE_TYPE_BU_CONFIRMATION,
} from 'shared/constants/type/index';

import {
  DEFAULT_LOCALE,
  EN,
  ID,
  PH,
  MY,
  SG,
  TH,
  VN,
  MS,
  VI,
  AU,
  ZH,
  JA,
  JP,
  KO,
  KR,
} from '../constants/locale';

import {
  WITH_DRIVER,
  WITHOUT_DRIVER,
  BOOKED as BOOKED_TRANSLATION,
  ISSUED as ISSUED_TRANSLATION,
  ISSUED_ACKNOWLEDGED as ISSUED_ACKNOWLEDGED_TRANSLATION,
  ISSUED_WAITING_FOR_ACKNOWLEDGE as ISSUED_WAITING_FOR_ACKNOWLEDGE_TRANSLATION,
  ISSUED_REJECTED as ISSUED_REJECTED_TRANSLATION,
  ISSUED_DRIVER_ASSIGNED as ISSUED_DRIVER_ASSIGNED_TRANSLATION,
  EXPIRED as EXPIRED_TRANSLATION,
  CANCELLED as CANCELLED_TRANSLATION,
  PICKUP_TIME,
  BOOKING_TIME,
  ASCENDING_LABEL,
  DESCENDING_LABEL,
  ALL_STATUS as ALL_STATUS_TRANSLATION
  // REFUND as REFUND_TRANSLATION,
  // NO_REFUND as NO_REFUND_TRANSLATION,
} from 'translations/common';

import {
  STANDBY,
  WAITING_FOR_BOOKING,
  ASSIGNED,
  REST,
  LEAVE_POOL,
  ABSENT_OUT,
  ON_THE_WAY,
} from 'translations/driver-queue';

import {
  ACKNOWLEDGED,
  DRIVER_CONFIRMED,
  TRIP_COMPLETED,
  REFUNDED,
  REQUEST_REFUND,
  RESCHEDULED,
  REQUEST_RESCHEDULE,
  POTENTIAL_LATE,
  PAX_AGREE_TO_WAIT,
  WAITING_FOR_ACKNOWLEDGE,
  DRIVER_ASSIGNED_STATUS,
  REQUEST_ACKNOWLEDGE,
  REQUEST_ASSIGN_DRIVER,
  CUTOFF_TYPE_END_OF_DAY,
  CUTOFF_TYPE_TWENTY_FOUR_HOURS,
} from 'translations/booking-manifest';

export const ALL_VALUE = 'All';
export const ANY_VALUE = 'Any';

export const TRANSMISSION = [
  { value: TYPE_TRANSMISSION_AUTOMATIC, label: 'Automatic' },
  { value: TYPE_TRANSMISSION_MANUAL, label: 'Manual' },
];

export const DRIVER_TYPE_WITH_LOCALE = (locale) => [
  { value: TYPE_DRIVER_WITH_DRIVER, label: WITH_DRIVER[locale] },
  { value: TYPE_DRIVER_WITHOUT_DRIVER, label: WITHOUT_DRIVER[locale] },
];

export const DRIVER_TYPE = DRIVER_TYPE_WITH_LOCALE(DEFAULT_LOCALE);

export const SUPPLIER_SERVICE_TYPE = [
  { value: TYPE_SUPPLIER_SERVICE_STANDARD, label: 'Standard' },
  { value: TYPE_SUPPLIER_SERVICE_PREMIUM, label: 'Premium' },
];

export const VEHICLE_TYPE = [
  { value: TYPE_VEHICLE_TYPE_MPV, label: 'MPV' },
  { value: TYPE_VEHICLE_COMPACT_MPV, label: 'Compact MPV' },
  { value: TYPE_VEHICLE_TYPE_MINI_MPV, label: 'Mini MPV' },
  { value: TYPE_VEHICLE_SUV, label: 'SUV' },
  { value: TYPE_VEHICLE_CITY_CAR, label: 'City Car' },
  { value: TYPE_VEHICLE_MINI_VAN, label: 'Mini Van' },
  { value: TYPE_VEHICLE_MINIBUS, label: 'Mini Bus' },
  { value: TYPE_VEHICLE_SEDAN, label: 'Sedan' },
  { value: TYPE_VEHICLE_LUXURY_SEDAN, label: 'Luxury Sedan' },
  { value: TYPE_VEHICLE_LIMOUSINE, label: 'Limousine' },
  { value: TYPE_VEHICLE_MOTORCYCLE, label: 'Motorcycle' },
  { value: TYPE_VEHICLE_MEDIUM_BUS, label: 'Medium Bus' },
  { value: TYPE_VEHICLE_BIG_BUS, label: 'Big Bus' },
  { value: TYPE_VEHICLE_LUXURY_BUS, label: 'Luxury Bus' },
  { value: TYPE_VEHICLE_BUS, label: 'Bus' },
  { value: TYPE_VEHICLE_PICKUP_TRUCK, label: 'Pick-up Truck' },
];

const ASCENDING = 'ASC';
const DESCENDING = 'DESC';

export const SORT_TYPE = [
  { value: ASCENDING, label: 'Oldest to Newest' },
  { value: DESCENDING, label: 'Newest to Oldest' },
];

export const SORT_TYPE_WITH_LOCALE = (locale) => [
  { value: ASCENDING, label: ASCENDING_LABEL[locale] },
  { value: DESCENDING, label: DESCENDING_LABEL[locale] },
];

export const PICKUP_MANAGEMENT_BOOKING_STATUS_WITH_LOCALE = (locale) => [
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_WAITING_FOR_ACKNOWLEDGE,
    label: WAITING_FOR_ACKNOWLEDGE[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_ACKNOWLEDGE,
    label: REQUEST_ACKNOWLEDGE[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_ACKNOWLEDGED,
    label: ACKNOWLEDGED[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_ASSIGN_DRIVER,
    label: REQUEST_ASSIGN_DRIVER[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_DRIVER_ASSIGNED,
    label: DRIVER_ASSIGNED_STATUS[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_DRIVER_CONFIRMED,
    label: DRIVER_CONFIRMED[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_TRIP_COMPLETED,
    label: TRIP_COMPLETED[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REFUNDED,
    label: REFUNDED[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_REFUND,
    label: REQUEST_REFUND[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_RESCHEDULED,
    label: RESCHEDULED[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_REQUEST_RESCHEDULE,
    label: REQUEST_RESCHEDULE[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_POTENTIAL_LATE,
    label: POTENTIAL_LATE[locale],
  },
  {
    value: TYPE_PICKUP_MANAGEMENT_BOOKING_STATUS_TYPE_PAX_AGREE_TO_WAIT,
    label: PAX_AGREE_TO_WAIT[locale],
  },
];

const PICKUP_TIME_VALUE = 'PICKUP_TIME';
const BOOKING_TIME_VALUE = 'BOOKING_TIME';

export const BOOKING_SORT_OPTIONS_WITH_LOCALE = (locale) => [
  { value: PICKUP_TIME_VALUE, label: PICKUP_TIME[locale] },
  { value: BOOKING_TIME_VALUE, label: BOOKING_TIME[locale] },
];

const BOOKED = 'BOOKED';
const ALL_STATUS = 'ALL_STATUS';

const ISSUED = 'ISSUED';
const ISSUED_WAITING_FOR_ACKNOWLEDGE = 'ISSUED_WAITING_FOR_ACKNOWLEDGE';
const ISSUED_ACKNOWLEDGED = 'ISSUED_ACKNOWLEDGED';
const ISSUED_DRIVER_ASSIGNED = 'ISSUED_DRIVER_ASSIGNED';
const ISSUED_REJECTED = 'ISSUED_REJECTED';

const EXPIRED = 'EXPIRED';
const CANCELLED = 'CANCELLED';
const CANCELLED_REFUND = 'CANCELLED_REFUND';
const CANCELLED_PARTIAL_REFUND = 'CANCELLED_PARTIAL_REFUND';
const CANCELLED_NO_REFUND = 'CANCELLED_NO_REFUND';

export const BOOKING_STATUS_WITH_LOCALE = (locale) => [
  { value: ALL_STATUS, label: ALL_STATUS_TRANSLATION[locale] },
  { value: BOOKED, label: BOOKED_TRANSLATION[locale] },

  { value: ISSUED_WAITING_FOR_ACKNOWLEDGE, label: ISSUED_WAITING_FOR_ACKNOWLEDGE_TRANSLATION[locale] },
  { value: ISSUED_ACKNOWLEDGED, label: ISSUED_ACKNOWLEDGED_TRANSLATION[locale] },
  { value: ISSUED_DRIVER_ASSIGNED, label: ISSUED_DRIVER_ASSIGNED_TRANSLATION[locale] },
  { value: ISSUED_REJECTED, label: ISSUED_REJECTED_TRANSLATION[locale] },

  { value: CANCELLED, label: CANCELLED_TRANSLATION[locale] },
  // { value: CANCELLED_REFUND, label: CANCELLED_TRANSLATION[locale] + ' - ' + REFUND_TRANSLATION[locale] },
  // { value: CANCELLED_NO_REFUND, label: CANCELLED_TRANSLATION[locale] + ' - ' + NO_REFUND_TRANSLATION[locale] },
  { value: EXPIRED, label: EXPIRED_TRANSLATION[locale] },
];

export const BOOKING_STATUS_EXTERNAL_WITH_LOCALE = (locale) => [
  { value: ISSUED, label: ISSUED_TRANSLATION[locale] },
  { value: CANCELLED, label: CANCELLED_TRANSLATION[locale] },
];

export const BOOKING_STATUS = BOOKING_STATUS_WITH_LOCALE(DEFAULT_LOCALE);

export const getBookingStatus = (value) => {
  switch (value) {
    case BOOKED:
      return TYPE_BOOKING_STATUS_BOOKED;

    case ISSUED:
    case ISSUED_ACKNOWLEDGED:
    case ISSUED_DRIVER_ASSIGNED:
    case ISSUED_REJECTED:
    case ISSUED_WAITING_FOR_ACKNOWLEDGE:
      return TYPE_BOOKING_STATUS_ISSUED;

    case EXPIRED:
      return TYPE_BOOKING_STATUS_EXPIRED;

    case CANCELLED:
    case CANCELLED_NO_REFUND:
    case CANCELLED_PARTIAL_REFUND:
    case CANCELLED_REFUND:
      return TYPE_BOOKING_STATUS_CANCELLED;

    default:
      return null;
  }
};

export const getPostIssuanceStatus = (bookingStatus) => {
  const bookingIssuedPrefix = `${ISSUED}_`;

  if (typeof bookingStatus === 'string' && bookingStatus.startsWith(bookingIssuedPrefix)) {
    return bookingStatus.replace(bookingIssuedPrefix, "");
  }

  return null;
};

// TODO: to be done
export const getCancellationType = (value) => {
  switch (value) {
    case CANCELLED_NO_REFUND:
      return null;

    case CANCELLED_PARTIAL_REFUND:
      return null;

    case CANCELLED_REFUND:
      return null;

    default:
      return null;
  }
};

export const getBookingSelectionValue = (status, cancellationType) => {
  if (status === TYPE_BOOKING_STATUS_CANCELLED) {
    // TODO: to be done
    return null;
  }

  if (status === TYPE_BOOKING_STATUS_BOOKED) {
    return BOOKED;
  }

  if (status === TYPE_BOOKING_STATUS_ISSUED) {
    return ISSUED;
  }
};

const FAILED_PAYMENT = 'FAILED_PAYMENT';
const PAYMENT_UPDATED = 'PAYMENT_UPDATED';

export const FAILED_PAYMENTS_STATUS = [
  { value: FAILED_PAYMENT, label: 'Failed Payment' },
  { value: PAYMENT_UPDATED, label: 'Payment Updated' },
];

const EN_ID = `${EN.toLowerCase()}_${ID}`;
const EN_EN = `${EN.toLowerCase()}_${EN}`;
const ID_ID = `${ID.toLowerCase()}_${ID}`;
const EN_PH = `${EN.toLowerCase()}_${PH}`;
const EN_MY = `${EN.toLowerCase()}_${MY}`;
const MS_MY = `${MS.toLowerCase()}_${MY}`;
const EN_SG = `${EN.toLowerCase()}_${SG}`;
const EN_TH = `${EN.toLowerCase()}_${TH}`;
const TH_TH = `${TH.toLowerCase()}_${TH}`;
const EN_VN = `${EN.toLowerCase()}_${VN}`;
const VI_VN = `${VI.toLowerCase()}_${VN}`;
const EN_AU = `${EN.toLowerCase()}_${AU}`;
// JP/KR
const JA_JP = `${JA.toLowerCase()}_${JP}`;
const KO_KR = `${KO.toLowerCase()}_${KR}`;
const EN_KR = `${EN.toLowerCase()}_${KR}`;
const EN_JP = `${EN.toLowerCase()}_${JP}`;

export const CONTENT_LOCALE = [
  { value: EN_AU, label: EN_AU },
  { value: EN_EN, label: EN_EN },
  { value: EN_ID, label: EN_ID },
  { value: ID_ID, label: ID_ID },
  { value: EN_MY, label: EN_MY },
  { value: MS_MY, label: MS_MY },
  { value: EN_PH, label: EN_PH },
  { value: EN_SG, label: EN_SG },
  { value: EN_TH, label: EN_TH },
  { value: TH_TH, label: TH_TH },
  { value: EN_VN, label: EN_VN },
  { value: VI_VN, label: VI_VN },
  // JP/KR
  { value: JA_JP, label: JA_JP },
  { value: KO_KR, label: KO_KR },
  { value: EN_KR, label: EN_KR },
  { value: EN_JP, label: EN_JP },
];

export const LANGUAGE = [
  { value: ZH, label: `Chinese (${ZH})` },
  { value: EN, label: `English (${EN})` },
  { value: ID, label: `Indonesian (${ID})` },
  { value: MS, label: `Malay (${MS})` },
  { value: TH, label: `Thai (${TH})` },
  { value: VI, label: `Vietnamese (${VI})` },
];

export const SUPPLIER_LANGUAGE = [
  { value: EN, label: `English (${EN})` },
  { value: ID, label: `Indonesian (${ID})` },
  { value: VI, label: `Vietnamese (${VI})` },
  // { value: ZH, label: `Chinese (${ZH})` },
  // { value: MS, label: `Malay (${MS})` },
  // { value: TH, label: `Thai (${TH})` },
];

export const MONTH = () => [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export const COUNTRY_CODE_WITH_ANY = [
  { label: 'Any', value: ANY_VALUE },
  ...countryOptions,
];

export const DRIVER_STATUS_OPTIONS_WITH_LOCALE = (locale) => [
  { value: TYPE_DRIVER_QUEUE_STATUS_STANDBY, label: STANDBY[locale] },
  {
    value: TYPE_DRIVER_QUEUE_STATUS_WAITING_FOR_BOOKING,
    label: WAITING_FOR_BOOKING[locale],
  },
  { value: TYPE_DRIVER_QUEUE_STATUS_ASSIGNED, label: ASSIGNED[locale] },
  { value: TYPE_DRIVER_QUEUE_STATUS_REST, label: REST[locale] },
  { value: TYPE_DRIVER_QUEUE_STATUS_LEAVE_POOL, label: LEAVE_POOL[locale] },
  { value: TYPE_DRIVER_QUEUE_STATUS_ABSENT_OUT, label: ABSENT_OUT[locale] },
  { value: TYPE_DRIVER_QUEUE_STATUS_ON_THE_WAY, label: ON_THE_WAY[locale] },
];

export const ROUTE_ZONAL_LEVEL_OPTIONS = () => [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export const INSURANCE_TYPE_OPTIONS = [
  {
    label: 'Insurance by Traveloka',
    value: TYPE_INSURANCE_INSURANCE_BY_TRAVELOKA,
  },
  {
    label: 'Insurance by Supplier',
    value: TYPE_INSURANCE_INSURANCE_BY_SUPPLIER,
  },
  {
    label: 'Insurance by Supplier and Traveloka',
    value: TYPE_INSURANCE_INSURANCE_BY_SUPPLIER_AND_TRAVELOKA,
  },
  {
    label: 'Insurance Not Available',
    value: TYPE_INSURANCE_INSURANCE_NOT_AVAILABLE,
  },
];

export const CUTOFF_TYPE_OPTIONS = (displayAll) => {
  const options = [
    {
      value: TYPE_CUTOFF_TYPE_TWENTY_FOUR_HOURS,
      label: CUTOFF_TYPE_TWENTY_FOUR_HOURS.EN,
    },
    { value: TYPE_CUTOFF_TYPE_END_OF_DAY, label: CUTOFF_TYPE_END_OF_DAY.EN },
  ];

  if (displayAll) return [{ value: 'ALL', label: 'All' }, ...options];
  return options;
};

export const PAYMENT_ELIGIBLE_TYPE_OPTIONS = [
  {
    label: 'Default',
    value: TYPE_PAYMENT_ELIGIBLE_TYPE_DEFAULT,
  },
  {
    label: 'BU Confirmation',
    value: TYPE_PAYMENT_ELIGIBLE_TYPE_BU_CONFIRMATION,
  },
];
